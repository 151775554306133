import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  CardTitle,
  Col,
  Form,
  Label,
  Row,
  Modal,
  ModalBody, ModalHeader,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import APIS from "../../apis"
import { withTranslation } from "react-i18next"
import Select from "react-select"

const AddFicheVoeux = props => {
  var userAuthEnseignant = JSON.parse(
    localStorage.getItem("userAuthEnseignant")
  )
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  var departement_id =userAuthEnseignant.user.departement_id
  var annee_id =userAuthEnseignant.user.annee_id
  var anneeScolaire = JSON.parse(localStorage.getItem("anneeScolaire"))
  var anneeUniversitaire=anneeScolaire.label
  //
  const [jours, setJours] = useState([
    { value: 1, label: "Lundi" },
    { value: 2, label: "Mardi" },
    { value: 3, label: "Mercredi" },
    { value: 4, label: "Jeudi" },
    { value: 5, label: "Vendredi" },
    { value: 6, label: "Samedi" },
  ])
  const [selectJour, setSelectJour] = useState("")
  const [niveau, setNiveau] = useState([])
  const [niveau_id, setNiveau_id] = useState("")
  const [niveau1, setNiveau1] = useState([])
  const [niveau_id1, setNiveau_id1] = useState("")
  const [type, setType] = useState([])
  const [type1, setType1] = useState([])
  const [specialite, setSpecialite] = useState([])
  const [specialite1, setSpecialite1] = useState([])
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("")
  const [matiere, setMatiere] = useState([])
  const [matiere1, setMatiere1] = useState([])
  const [arrayFiche, setArrayFiche] = useState([
    { ordre: "", niveau_id: "", specialite_id: "", matiere_id: "", type: "" },
  ])
  const [arrayFiche1, setArrayFiche1] = useState([
    {
      ordre1: "",
      niveau_id1: "",
      specialite_id1: "",
      matiere_id1: "",
      type1: "",
    },
  ])
  const [modal, setModal] = useState(false)
  const [modalJours, setModalJours] = useState(false)
  const [indexLigne, setIndexLigne] = useState("")
  const [modal1, setModal1] = useState(false)
  const [indexLigne1, setIndexLigne1] = useState("")
  const [indexLigneJours, setIndexLigneJours] = useState("")
  const [affiche, setAffiche] = useState(false)
  const [ordre, setOrdre] = useState([
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
  ])
  const [session, setSession] = useState([
    {value:1,label:"Matin"},
    {value:2,label:"Après-Midi"},
  ])
  const [enseignant, setEnseignant] = useState([]);
  const [selectEnseignant, setSelectEnseignant] = useState('');
  const [arrayJour, setArrayJour] = useState([{ jour_id: "" ,session:""}])
  const addLigneJour = () => {
    let element = {
      jour_id: "",
      session: "",
    }
    setArrayJour([...arrayJour, element])
  }
  const addJour = (event, index) => {
    let jour_id = event.value

    setArrayJour(
        arrayJour.map((el, id) =>
            id === index ? Object.assign(el, { jour_id: jour_id }) : el
        )
    )
  }
  const addSession = (event, index) => {
    let session = event.value
    setArrayJour(
        arrayJour.map((el, id) =>
            id === index ? Object.assign(el, { session: session }) : el
        )
    )
  }
  useEffect(async () => {
    const res = await APIS.get("niveau/select").then(res => {
      setNiveau(res.data.Niveau)
    })
    const resN = await APIS.get("niveau/select_mastere").then(resN => {
      setNiveau1(resN.data.Niveau)
    })
    const resEnseignant = await APIS.get("enseignant/select_by_departement").then(resEnseignant => {
      setEnseignant(resEnseignant.data.Enseignant)
    })
  }, [])

  const addLigne = () => {
    let element = {
      ordre: "",
      niveau_id: "",
      specialite_id: "",
      matiere_id: "",
      type: "",
    }
    setArrayFiche([...arrayFiche, element])
  }

  const addLigne1 = () => {
    let element = {
      ordre1: "",
      niveau_id1: "",
      specialite_id1: "",
      matiere_id1: "",
      type1: "",
    }
    setArrayFiche1([...arrayFiche1, element])
  }

  const toggle = indexOfLigne => {
    setModal(!modal)
    setIndexLigne(indexOfLigne)
  }
  const toggleJours = indexOfLigne => {
    setModalJours(!modalJours)
    setIndexLigne(indexOfLigne)
  }
  const toggleDeleteJours = indexOfLigne => {
    arrayJour.splice(indexLigneJours, 1)
    setModalJours(false)
  }

  const toggle1 = indexOfLigne1 => {
    setModal1(!modal1)
    setIndexLigne1(indexOfLigne1)
  }

  const toggleDelete = async () => {
    arrayFiche.splice(indexLigne, 1)
    setModal(false)
  }

  const toggleDelete1 = async () => {
    arrayFiche1.splice(indexLigne1, 1)
    setModal1(false)
  }

  const addOrdre = (event, index) => {
    let ordre = event
    setArrayFiche(
      arrayFiche.map((el, id) =>
        id === index ? Object.assign(el, { ordre: ordre.value }) : el
      )
    )
  }

  const addNiveau = (event, index) => {
    let niveau_id = event
    setNiveau_id(event.value)
    const res = APIS.post("specialite/get_by_niveau_licence", {
      niveau_id: event.value,
      enseignant_id: enseignant_id,
      semestre_id: selectSemestre.value,
      departement_id:departement_id
    }).then(res => {
      setSpecialite(res.data.Specialites)
    })
    setArrayFiche(
      arrayFiche.map((el, id) =>
        id === index ? Object.assign(el, { niveau_id: niveau_id.value }) : el
      )
    )
  }

  const addSpecialite = (event, index) => {
    let specialite = event
    const res = APIS.post("matiere/get_by_specialite", {
      specialite_id: event.value,
      enseignant_id: enseignant_id,
      semestre_id: selectSemestre.value,
      niveau_id: niveau_id,
      departement_id:departement_id
    }).then(res => {
      setMatiere(res.data.Matiere)
    })
    setArrayFiche(
      arrayFiche.map((el, id) =>
        id === index
          ? Object.assign(el, { specialite_id: specialite.value })
          : el
      )
    )
  }

  const addMatiere = (event, index) => {
    let matiere = event
    const res = APIS.post("matiere/get_detail", {
      matiere_id: event.value,
    }).then(res => {
      setType(res.data.Matiere)
    })
    setArrayFiche(
      arrayFiche.map((el, id) =>
        id === index ? Object.assign(el, { matiere_id: matiere.value }) : el
      )
    )
  }

  const addType = (event, index) => {
    let type = event
    setArrayFiche(
      arrayFiche.map((el, id) =>
        id === index ? Object.assign(el, { type: type.value }) : el
      )
    )
  }

  const addOrdre1 = (event, index) => {
    let ordre = event
    setArrayFiche1(
      arrayFiche1.map((el, id) =>
        id === index ? Object.assign(el, { ordre1: ordre.value }) : el
      )
    )
  }

  const addNiveau1 = (event, index) => {
    let niveau_id = event
    setNiveau_id1(event.value)
    const res = APIS.post("specialite/get_by_niveau_mastere", {
      niveau_id: event.value,
      enseignant_id: enseignant_id,
      semestre_id: selectSemestre.value,
      departement_id:departement_id
    }).then(res => {
      setSpecialite1(res.data.Specialites)
    })
    setArrayFiche1(
      arrayFiche1.map((el, id) =>
        id === index ? Object.assign(el, { niveau_id1: niveau_id.value }) : el
      )
    )
  }

  const addSpecialite1 = (event, index) => {
    let specialite = event
    const res = APIS.post("matiere/get_by_specialite2", {
      specialite_id: event.value,
      enseignant_id: enseignant_id,
      semestre_id: selectSemestre.value,
      niveau_id: niveau_id1,
      departement_id:departement_id
    }).then(res => {
      setMatiere1(res.data.Matiere)
    })
    setArrayFiche1(
      arrayFiche1.map((el, id) =>
        id === index
          ? Object.assign(el, { specialite_id1: specialite.value })
          : el
      )
    )
  }

  const addMatiere1 = (event, index) => {
    let matiere = event
    const res = APIS.post("matiere/get_detail", {
      matiere_id: event.value,
    }).then(res => {
      setType1(res.data.Matiere)
    })
    setArrayFiche1(
      arrayFiche1.map((el, id) =>
        id === index ? Object.assign(el, { matiere_id1: matiere.value }) : el
      )
    )
  }

  const addType1 = (event, index) => {
    let type = event
    setArrayFiche1(
      arrayFiche1.map((el, id) =>
        id === index ? Object.assign(el, { type1: type.value }) : el
      )
    )
  }

  const display = () => {
    if (selectSemestre != "" && (arrayJour.length > 0 && arrayJour.every(item => item.jour_id && item.session))) {
      setAffiche(true)
    } else {
      toast.error("Veuillez choisir un semestre  et Jour", {
        containerId: "A",
      })
    }
  }

  const save = async () => {
    var etat = true
    for (let i = 0; i < arrayFiche.length; i++) {
      var ordre = arrayFiche[i].ordre
      var type = arrayFiche[i].type
      var niveau_id = arrayFiche[i].niveau_id
      var specialite_id = arrayFiche[i].specialite_id
      var matiere_id = arrayFiche[i].matiere_id
      if (
        ordre == "" ||
        type == "" ||
        niveau_id == "" ||
        specialite_id == "" ||
        matiere_id == ""
      ) {
        var etat = false
        toast.error("⛔ Remplir les champs vides", {
          containerId: "A",
        })
      }
    }
    for (let i = 0; i < arrayFiche1.length; i++) {
      var ordre1 = arrayFiche1[i].ordre1
      var type1 = arrayFiche1[i].type1
      var niveau_id1 = arrayFiche1[i].niveau_id1
      var specialite_id1 = arrayFiche1[i].specialite_id1
      var matiere_id1 = arrayFiche1[i].matiere_id1
      if (
        ordre1 == "" ||
        type1 == "" ||
        niveau_id1 == "" ||
        specialite_id1 == "" ||
        matiere_id1 == ""
      ) {
        var etat = false
        toast.error("⛔ Remplir les champs vides", {
          containerId: "A",
        })
      }
    }
    for (let i = 0; i < arrayJour.length; i++) {
      var jour_id = arrayJour[i].jour_id
      var session = arrayJour[i].session
      if(jour_id  == "" || session == "" ){
        var etat = false
        toast.error("⛔ Remplir les champs vides", {
          containerId: "A",
        })
      }
    }
    if (etat) {
      const res = await APIS.post("fiche/add", {
        semestre_id: selectSemestre.value,
        departement_id:departement_id,
        enseignant_id:enseignant_id,
        annee_id:annee_id,
        jour: arrayJour,
        ligne: arrayFiche,
        ligne1: arrayFiche1,
      })
      if (res.data.msg === "order invalide") {
        toast.error("Ordre invalide", {
          containerId: "A",
        })
      } else if (res.data.errNum == 403) {
        toast.error(
          "Vous avez déja ajouter une fiche de voeux sur cette semestre",
          {
            containerId: "A",
          }
        )
      } else {
        toast.success(" Votre fiche de voeux est ajouté avec succées ", {
          containerId: "A",
        })
        props.setSection(1)
      }
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Semestre")} :
                </Label>
                <Select
                  options={semestre}
                  isSearchable={true}
                  onChange={e => setSelectSemestre(e)}
                />
              </div>
            </Col>
            {/*<Col md={6}>*/}
            {/*  <div className="mb-3">*/}
            {/*    <Label for="basicpill-firstname-input1">*/}
            {/*      {props.t("Choix des jours")} :*/}
            {/*    </Label>*/}
            {/*    <Select*/}
            {/*      isMulti={true}*/}
            {/*      options={jours}*/}
            {/*      isSearchable={true}*/}
            {/*      onChange={e => setSelectJour(e)}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</Col>*/}
          </Row>
          <Row>
            <Label for="basicpill-firstname-input1">
              {props.t("Choix des jours")} :
            </Label>
            <table className="table table-bordered mt-4">
              <thead>
              <tr>
                <th style={{textAlign: "center"}} scope="col">
                  Jours
                </th>
                <th style={{textAlign: "center"}} scope="col">
                  Choix du jours
                </th>
                <th style={{textAlign: "center"}} scope="col">
                  <i
                      style={{
                        color: "#a0ceb8",
                        cursor: "pointer",
                      }}
                      className="fas fa-plus"
                      onClick={addLigneJour}
                  />
                </th>
              </tr>
              </thead>
              {arrayJour.map((el, index) => (
                  <tbody key={index}>
                  <tr>
                    <td style={{textAlign: "center"}} scope="col">
                      <Select
                          options={jours}
                          isSearchable={true}
                          onChange={e => addJour(e, index)}
                      />
                    </td>
                    <td style={{textAlign: "center"}} scope="col">
                      <Select
                          options={session}
                          isSearchable={true}
                          onChange={e => addSession(e, index)}
                      />
                    </td>
                    <td className="col-md-2" style={{textAlign: "center"}}>
                      <i
                          style={{color: "#cc0000"}}
                          className="mdi mdi-delete font-size-18"
                          id="deletetooltip"
                          onClick={() => toggleJours(index)}
                      />
                    </td>
                  </tr>
                  </tbody>
              ))}
            </table>
          </Row>
          {/*<Row>*/}
          {/*  <Col md={6}>*/}
          {/*    <div className="mb-3">*/}
          {/*      <Label for="basicpill-firstname-input1">*/}
          {/*        {props.t("Enseignant")} :*/}
          {/*      </Label>*/}
          {/*      <Select*/}
          {/*          options={enseignant}*/}
          {/*          isSearchable={true}*/}
          {/*          onChange={e => setSelectEnseignant(e)}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </Col>*/}

          {/*</Row>*/}
          <div
            className="mt-4"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              color="info"
              type="button"
              className="btn btn-primary"
              onClick={display}
            >
              {props.t("Afficher")}
            </Button>
          </div>
          {affiche ? (
            <Fragment>
              {" "}
              <div className="mt-4 mb-4">
                <h5>
                  Veuillez indiquer vos choix concernant les matières que vous
                  désiriez assurer au cours de l’année universitaire {anneeUniversitaire},
                  et de préciser la forme d’enseignement voulue (Cours, TD, TP,
                  Autre).
                </h5>
              </div>
              <CardTitle style={{ color: "#556ee6" }} className="h4 mb-2 mt-2">
                Choix des matiéres pour licence:
              </CardTitle>
              <table className="table table-bordered mt-4">
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }} scope="col">
                      Ordre de préférence
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Niveau
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Spécialité
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Matiere
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Type seance
                    </th>
                    {arrayFiche.length + arrayFiche1.length <= 9 ? (
                      <th style={{ textAlign: "center" }} scope="col">
                        <i
                          style={{
                            color: "#a0ceb8",
                            cursor: "pointer",
                          }}
                          className="fas fa-plus"
                          onClick={addLigne}
                        />
                      </th>
                    ) : null}
                  </tr>
                </thead>
                {arrayFiche.map((el, index) => (
                  <tbody key={index}>
                    <tr>
                      <td className="col-md-2">
                        <Select
                          options={ordre}
                          isSearchable={true}
                          onChange={e => addOrdre(e, index)}
                        />
                      </td>
                      <td className="col-md-2">
                        <Select
                          options={niveau}
                          isSearchable={true}
                          onChange={e => addNiveau(e, index)}
                        />
                      </td>
                      <td className="col-md-3">
                        <Select
                          options={specialite}
                          isSearchable={true}
                          onChange={e => addSpecialite(e, index)}
                        />
                      </td>
                      <td className="col-md-3">
                        <Select
                          options={matiere}
                          isSearchable={true}
                          onChange={e => addMatiere(e, index)}
                        />
                      </td>
                      <td className="col-md-2">
                        <Select
                          options={type}
                          isSearchable={true}
                          onChange={e => addType(e, index)}
                        />
                      </td>
                      <td className="col-md-1" style={{ textAlign: "center" }}>
                        <i
                          style={{ color: "#cc0000", cursor: "pointer" }}
                          className="mdi mdi-delete font-size-18"
                          id="deletetooltip"
                          onClick={() => toggle(index)}
                        />
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
              <CardTitle style={{ color: "#556ee6" }} className="h4 mb-2 mt-2">
                Choix des matiéres pour mastère:
              </CardTitle>
              <table className="table table-bordered mt-4">
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }} scope="col">
                      Ordre de préférence
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Niveau
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Spécialité
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Matiere
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Type seance
                    </th>
                    {arrayFiche.length + arrayFiche1.length <= 9 ? (
                      <th style={{ textAlign: "center" }} scope="col">
                        <i
                          style={{
                            color: "#a0ceb8",
                            cursor: "pointer",
                          }}
                          className="fas fa-plus"
                          onClick={addLigne1}
                        />
                      </th>
                    ) : null}
                  </tr>
                </thead>
                {arrayFiche1.map((el, index) => (
                  <tbody key={index}>
                    <tr>
                      <td className="col-md-2">
                        <Select
                          options={ordre}
                          isSearchable={true}
                          onChange={e => addOrdre1(e, index)}
                        />
                      </td>
                      <td className="col-md-2">
                        <Select
                          options={niveau1}
                          isSearchable={true}
                          onChange={e => addNiveau1(e, index)}
                        />
                      </td>
                      <td className="col-md-3">
                        <Select
                          options={specialite1}
                          isSearchable={true}
                          onChange={e => addSpecialite1(e, index)}
                        />
                      </td>
                      <td className="col-md-3">
                        <Select
                          options={matiere1}
                          isSearchable={true}
                          onChange={e => addMatiere1(e, index)}
                        />
                      </td>
                      <td className="col-md-2">
                        <Select
                          options={type1}
                          isSearchable={true}
                          onChange={e => addType1(e, index)}
                        />
                      </td>
                      <td className="col-md-1" style={{ textAlign: "center" }}>
                        <i
                          style={{ color: "#cc0000", cursor: "pointer" }}
                          className="mdi mdi-delete font-size-18"
                          id="deletetooltip"
                          onClick={() => toggle1(index)}
                        />
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <Col lg="6">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    className="text-center mt-4"
                  >
                    <Button
                      type="button"
                      color="warning"
                      className="btn btn-warning  mb-2 me-2"
                      onClick={props.back}
                    >
                      {props.t("Annuler")}
                    </Button>
                  </div>
                </Col>
                <Col lg="6">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    className="text-center mt-4"
                  >
                    <button
                      type="button"
                      className="btn btn-primary "
                      onClick={save}
                    >
                      {props.t("Confirmer")}
                    </button>
                  </div>
                </Col>
              </div>
            </Fragment>
          ) : null}
        </Form>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggle}
        >
          {props.t("Suppression ligne")}
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  onClick={toggleDelete}
                  color="info"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal1} toggle={toggle1} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggle1}
        >
          {props.t("Suppression ligne")}
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  onClick={toggleDelete1}
                  color="info"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalJours} toggle={toggleJours} centered={true}>
        <ModalHeader toggle={toggle} tag="h4">
          {"Suppression d'une ligne"}{" "}
        </ModalHeader>
        <ModalBody>
          <div
              style={{
                fontFamily: "Montserrat, sans-serif",
                FontSize: "14px",
                FontWeight: "700",
                LineHeight: "18.375px",
              }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Voulez-vous supprimer cette ligne ?</p>
            </div>
            <div
                className="hvr-push"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
            >
              <div>
                <Button
                    onClick={toggleDeleteJours}
                    color="info"
                    className="btn-rounded "
                >
                  <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                    onClick={toggleJours}
                    color="danger"
                    className="btn-rounded "
                >
                  <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withTranslation()(AddFicheVoeux)
AddFicheVoeux.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
