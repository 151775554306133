import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  CardTitle,
  Col,
  Form,
  Label,
  Row,
  Modal,
  ModalBody,
  Container,
  Card,
  CardBody,
  Spinner, ModalHeader,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import APIS from "../../apis"
import { withTranslation } from "react-i18next"
import Select from "react-select"

const EditFicheVoeuxSurveillance = props => {
  var userAuthEnseignant = JSON.parse(
      localStorage.getItem("userAuthEnseignant")
  )
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  var departement_id =userAuthEnseignant.user.departement_id
  var annee_id =userAuthEnseignant.user.annee_id
  //
  const [jours, setJours] = useState([
    { value: 1, label: "Lundi" },
    { value: 2, label: "Mardi" },
    { value: 3, label: "Mercredi" },
    { value: 4, label: "Jeudi" },
    { value: 5, label: "Vendredi" },
    { value: 6, label: "Samedi" },
  ])
  const [selectJour, setSelectJour] = useState("")
  const [selectSemestre, setSelectSemestre] = useState("")
  const [loading, setLoading] = useState(false)
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
  ])

  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await APIS.post("fichesurveillance/getbyid", { id }).then(res => {
      setSelectSemestre(res.data.fiche.semestre)
      setSelectJour(res.data.fiche.jours)
      setLoading(true)
    })
  }, [])

  const edit = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    if (selectSemestre != "") {
      if (selectJour.length > 0) {
        const res = await APIS.post("fichesurveillance/update", {
          id: id,
          semestre_id: selectSemestre.value,
          jour: selectJour,
        })
        if (res.data.msg === "Fiche voeux mis a jour avec succes") {
          toast.success(" Votre fiche de voeux est ajouté avec succées ", {
            containerId: "A",
          })
          props.history.push("/FicheVoeuxSurveillance")
        }
      } else {
        toast.error("Veuillez choisir les jours", {
          containerId: "A",
        })
      }
    } else {
      toast.error("Veuillez choisir un semestre ", {
        containerId: "A",
      })
    }
  }

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Card>
              <CardBody>
                {loading ? (
                    <Row>
                      <Form>
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                {props.t("Semestre")} :
                              </Label>
                              <Select
                                  options={semestre}
                                  isSearchable={true}
                                  value={selectSemestre}
                                  onChange={e => setSelectSemestre(e)}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                {props.t("Jours")} :
                              </Label>
                              <Select
                                options={jours}
                                isSearchable={true}
                                isMulti={true}
                                value={selectJour}
                                onChange={e => setSelectJour(e)}
                              />
                            </div>
                          </Col>
                        </Row>
                    
                      </Form>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <Col lg="6">
                          <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              className="text-center mt-4"
                          >
                            <Button
                                type="button"
                                color="warning"
                                className="btn btn-warning  mb-2 me-2"
                                onClick={() => props.history.push("/FicheVoeuxSurveillance")}
                            >
                              Annuler
                            </Button>
                          </div>
                        </Col>
                        <Col lg="6">
                          <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                              className="text-center mt-4"
                          >
                            <button
                                type="button"
                                className="btn btn-primary "
                                onClick={edit}
                            >
                              Confirmer
                            </button>
                          </div>
                        </Col>
                      </div>
                    </Row>
                ) : (
                    <div>
                      <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                      >
                        {" "}
                        <Spinner type="grow" className="ms-6" color="primary" />
                      </div>
                      <h4
                          style={{ textAlign: "center", marginTop: "2%" }}
                          className="ms-6"
                      >
                        {" "}
                        {"Chargement"} ...{" "}
                      </h4>
                    </div>
                )}
                <ToastContainer
                    transition={Slide}
                    enableMultiContainer
                    containerId={"A"}
                    position={toast.POSITION.TOP_RIGHT}
                    autoClose={2500}
                />
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
  )
}

export default withTranslation()(EditFicheVoeuxSurveillance)
EditFicheVoeuxSurveillance.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
